var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "70%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        title: _vm.$t("lang_role") + ": " + _vm.roleName,
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-card",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.formLoadingManage,
                      expression: "formLoadingManage",
                    },
                  ],
                  staticClass: "box-card",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [_c("span", [_vm._v("管理类菜单")])]
                  ),
                  _c("el-tree", {
                    ref: "menuTreeRefManage",
                    attrs: {
                      data: _vm.menuListManage,
                      "show-checkbox": "",
                      "check-strictly": "",
                      "default-expand-all": "",
                      "node-key": "id",
                      "default-expanded-keys": [],
                      "default-checked-keys": _vm.defaultCheckedKeys,
                      props: _vm.defaultMenuTreeProps,
                    },
                    on: { "check-change": _vm.changeUser },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-card",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.formLoadingUser,
                      expression: "formLoadingUser",
                    },
                  ],
                  staticClass: "box-card",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", [_vm._v("用户类菜单")]),
                      _c("span", { staticClass: "clearfix-tenant" }, [
                        _vm._v("选择租户："),
                      ]),
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择租户" },
                          model: {
                            value: _vm.tenantId,
                            callback: function ($$v) {
                              _vm.tenantId = $$v
                            },
                            expression: "tenantId",
                          },
                        },
                        _vm._l(_vm.tenantList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-tree", {
                    ref: "menuTreeRefUser",
                    attrs: {
                      data: _vm.menuListUser,
                      "show-checkbox": "",
                      "check-strictly": "",
                      "default-expand-all": "",
                      "node-key": "id",
                      "default-expanded-keys": [],
                      "default-checked-keys": _vm.defaultCheckedKeys,
                      props: _vm.defaultMenuTreeProps,
                    },
                    on: { "check-change": _vm.changeUser },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveForm } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }