import HttpUtil from '@/utils/HttpUtil';

// 获取权限分页数据
export const getPermissionPage: any = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'admin/permission/query/page';
  return HttpUtil.post(url, params);
};

// 添加权限
export const addPermission: any = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'admin/permission/create';
  return HttpUtil.post(url, params);
};

// 获取权限绑定的资源信息
export const getBindResources: any = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'admin/permission/getBindResources';
  return HttpUtil.post(url, params);
};

// 权限绑定资源
export const bindResources: any = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'admin/permission/bindResources';
  return HttpUtil.post(url, params);
};

// 删除权限
export const removePermission: any = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'admin/permission/delete';
  return HttpUtil.post(url, params);
};

