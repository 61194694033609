






































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { getPermissionPage } from '@/api/system/PermissionApi';
import { getRolePermissions, setRolePermissions } from '@/api/system/RoleApi';

@Component({
  name: 'RoleBindPermissions'
})
export default class RoleBindPermissions extends Vue {
  // ref
  @Ref() readonly tableRef;

  // prop
  // 弹窗显隐
  @Prop({ default: false }) private visible!: boolean;
  // 角色id
  @Prop({ default: '' }) private roleId!: string;
  // 角色名称
  @Prop({ default: '' }) private roleName!: string;

  // 列表加载状态
  private tableLoading: boolean = false;
  // 表单搜索Model
  private searchForm: any = { name: '' };

  // 权限列表
  private permissionList: any[] = [];
  // 选中的权限列表
  private selectPermissionList: any[] = [];
  private selectPermissionIdList: any[] = [];

  // 分页参数
  private pageIndex: number = 1;
  // 每页大小
  private pageSize: number = 10;
  // 总条数
  private total: number = 0;
  // 当前页
  private currentPage: number = 1;

  /**
   * 监听弹窗显示隐藏
   * @param val
   */
  @Watch('visible')
  visibleWatcher(val) {
    if (val) {
      // 如果有传入角色id则查询权限列表
      if (this.roleId) {
        // 获取角色绑定的权限列表
        this.getRoleBindPermissionList();
      }
    } else {
      this.clean();
    }
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.clean();
    this.$emit('success', {});
    this.updateVisible(false);
  }

  clean() {
    this.selectPermissionList = [];
    this.selectPermissionIdList = [];
    this.searchForm.name = '';
    this.tableRef.clearSelection();
  }

  /**
   * update visible
   * @param value
   */
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  /**
   * 保存表单
   */
  saveForm() {
    let list = this.selectPermissionList;
    list = list.map(item => item.id);

    setRolePermissions(this.roleId, list)
      .then((res: Record<any, any>) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          // 关闭弹窗
          this.handleCloseDialog();
          this.$emit('success', res.data);
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {
        // 关闭加载框
      });
  }

  /**
   * 搜索
   */
  handleSearch() {
    this.getPermissionList();
  }

  /**
   * 重置搜索
   */
  resetSearch() {
    // 置空搜索表单
    this.pageIndex = 1;
    this.searchForm = { name: '' };
    this.getPermissionList();
  }

  /**
   * 获取权限列表
   */
  getPermissionList() {
    let params = {
      name: this.searchForm.name,
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.tableLoading = true;
    getPermissionPage(params)
      .then((res: Record<any, any>) => {
        if (res.code == '1' && res.data) {
          this.permissionList = res.data.list;
          this.total = res.data.total;
          this.selectTable();
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {
        this.tableLoading = false;
      });
  }

  /**
   * 获取角色绑定的权限列表
   */
  getRoleBindPermissionList() {
    this.tableLoading = true;
    getRolePermissions(this.roleId)
      .then((res: Record<any, any>) => {
        if (res.code == '1') {
          this.selectPermissionIdList = res.data;

          // 获取权限列表
          this.getPermissionList();
        }
      })
      .finally(() => {
        this.tableLoading = false;
      });
  }

  /**
   * 改变分页大小
   */
  changePageSize(pageSize: number) {
    if (pageSize < 1) {
      this.pageSize = 1;
    } else if (pageSize >= this.total) {
      this.pageSize = this.total;
    } else {
      this.pageSize = pageSize;
    }
    this.getPermissionList();
  }

  /**
   * 跳转至某页
   */
  changePage(page: number) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.getPermissionList();
  }

  /**
   * 列表选中
   * @param val
   */
  handleSelectionChange(val) {
    this.selectPermissionList = val;
  }

  /**
   * 表格选中
   */
  selectTable() {
    if (this.selectPermissionIdList) {
      this.tableLoading = true;
      this.selectPermissionIdList.forEach(item => {
        let filter = this.permissionList.filter(row => row.id === item);
        if (filter.length > 0) {
          this.tableRef.toggleRowSelection(filter[0]);
        }
      });
      this.tableLoading = false;
    }
  }

  /**
   * 记录每行的key值
   * @param row
   */
  getRowKey(row) {
    return row.id;
  }

  get dialogVisible() {
    return this.visible;
  }

  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
