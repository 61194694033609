



































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import MenuApi from '@/api/system/MenuApi';
import { getRoleMenus, setRoleMenus } from '@/api/system/RoleApi';
import { TenantPage } from '@/api/system/TenantApi';

@Component({
  name: 'RoleBindMenus'
})
export default class RoleBindMenus extends Vue {
  // ref
  @Ref() readonly menuTreeRefUser;
  @Ref() readonly menuTreeRefManage;

  // prop
  // 弹窗显隐
  @Prop({ default: false }) private visible!: boolean;
  // 角色id
  @Prop({ default: '' }) private roleId!: string;
  // 角色名称
  @Prop({ default: '' }) private roleName!: string;

  // 表单加载状态
  formLoadingUser: boolean = false;
  formLoadingManage: boolean = false;
  defaultCheckedKeysLoad: boolean = false;

  // 菜单列表
  menuList: any[] = [];
  menuListUser: any[] = [];
  menuListManage: any[] = [];

  tenantId: string = '';
  tenantList: any = [];
  // 选中的key
  private defaultCheckedKeys: string[] = [];
  private defaultMenuTreeProps: object = { children: 'children', label: 'displayName' };

  /**
   * 监听弹窗显示隐藏
   * @param val
   */
  @Watch('visible')
  visibleWatcher(val) {
    if (val) {
      this.defaultCheckedKeysLoad = false;
      // 如果有传入角色id则查询菜单列表
      if (this.roleId) {
        this.getUserMenu(this.tenantId);
        this.getManageMenu();

        // 获取角色绑定的菜单

        getRoleMenus(this.roleId).then((res: Record<any, any>) => {
          if (res.code == '1') {
            if (res.data) {
              this.defaultCheckedKeys = res.data;
            }
            setTimeout(() => {
              this.defaultCheckedKeysLoad = true;
            }, 100);
          } else {
            this.$message.error(res.message);
          }
        });
      }
    }
  }

  @Watch('tenantId')
  changeTenantList(tenantId) {
    this.getUserMenu(tenantId);
  }

  created() {
    this.getTenantList();
    if (this.$store.getters.user?.tenantId) {
      this.tenantId = this.$store.getters.user.tenantId;
    }
  }

  changeUser(data, checked) {
    if (this.defaultCheckedKeysLoad && data.id) {
      if (checked) {
        this.defaultCheckedKeys.push(data.id);
      } else {
        const index = this.defaultCheckedKeys.findIndex(item => item == data.id);
        if (index >= 0) this.defaultCheckedKeys.splice(index, 1);
      }
    }
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.defaultCheckedKeys = [];
    this.$emit('success', {});
    this.updateVisible(false);
  }

  getUserMenu(tenantId?) {
    this.formLoadingUser = true;
    this.menuListUser = [];
    MenuApi.getMenuList({ menuType: '2', tenantId: tenantId ? tenantId : '-1' })
      .then((res: Record<any, any>) => {
        if (res.code == '1' && res.data) {
          this.menuListUser = res.data;
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {
        this.formLoadingUser = false;
      });
  }
  getManageMenu() {
    this.formLoadingManage = true;
    this.menuListManage = [];
    MenuApi.getMenuList({ menuType: '1', tenantId: '-1' })
      .then((res: Record<any, any>) => {
        if (res.code == '1' && res.data) {
          this.menuListManage = res.data;
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {
        this.formLoadingManage = false;
      });
  }
  // 获取租户列表
  getTenantList() {
    let params: any = {
      page: {
        pageIndex: 1,
        pageSize: 999
      }
    };
    TenantPage(params).then((res: any) => {
      if (res.code == '1' && res.data) {
        this.tenantList = res.data.list;
      } else {
        this.$message.warning(res.message);
      }
    });
  }

  /**
   * update visible
   * @param value
   */
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  /**
   * 保存表单
   */
  saveForm() {
    setRoleMenus(this.roleId, this.defaultCheckedKeys)
      .then((res: Record<any, any>) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          // 关闭弹窗
          this.dialogVisible = false;
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {
        // 关闭加载框
      });
  }

  get dialogVisible() {
    return this.visible;
  }

  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
